<template>
  <div class="news">
    <div class="top-banner">
      <div class="title container">
        <h1>新闻资讯</h1>
        <h2>NEWS</h2>
      </div>
    </div>
    <div class="wrapper container space">
      <div class="news-item" v-for="(item, i) in newsList" :key="i" @click="toNewsDetail(i)">
        <div class="news-cover" :style="'background-image:url(' + item.image + ')'">
        </div>
        <div class="news-info">
          <h2>{{item.title}}</h2>
          <div class="content">{{item.content}}</div>
          <div class="time">{{item.time}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { newJson } from '@/json/news.js'
export default {
  name: 'News',
  data(){
    return {
      newsList: newJson,
    }
  },
  created(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0
  },
  methods: {
    toNewsDetail(i){
      this.$router.push({path: '/newsDetail', query: {idx: i}})
    }
  }
}
</script>

<style lang="scss">
.news {
  .wrapper {
    // padding-top: 100px;
    .news-item {
      display: flex;
      box-shadow:0px 2px 40px 0px rgba(0,0,0,0.2);
      margin-bottom: 50px;
      -moz-transition: all ease-in-out .3s;
      -webkit-transition: all ease-in-out .3s;
      transition: all ease-in-out .3s;
      cursor: pointer;
      .news-cover {
        flex: 1;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
      .news-info {
        width: 600px;
        overflow: hidden;
        height: 400px;
        padding: 40px;
        display: flex;
        flex-direction: column;
        h2 {
          margin-bottom: 20px;
          font-weight: bold;
        }
        .content {
          line-height: 30px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          line-clamp:4;
          -webkit-box-orient: vertical;
        }
        .time {
          color:rgba(51,51,51,.3);
          margin: 30px 30px 10px 0;
        }
      }
    }
    .news-item:hover {
      transform: scale(1.02);
    }
  }
}
@media (max-width: 1024px){
  .news {
    .wrapper {
      // padding: 30px 5vw;
      .news-item {
        display: block;
        margin-bottom: 20px;
        .news-cover {
          width: 100%;
          height: 40vw;
        }
        .news-info {
          width: 100%;
          height: auto;
          padding: 20px;
          h2 {
            font-size: 18px;
            mask-border: 10px;
          }
          p {
            font-size: 14px;
            line-height: 25px;
          }
        }
      }
    }
  }
}
</style>