export const newJson = [
	{
		"title": "Kate Somerville凯诗薇亮相进博会高端奢侈品区",
		"time": "2019-11-11",
		"image": require('@/assets/news/news_5.png'),
		"content": `11月5日至11月10日, Kate Somerville凯诗薇亮相第二届中国国际进口博览会(China International import Expo,简称CIIE或进博会)。`,
		"desc": `<p>11月5日至11月10日, Kate Somerville凯诗薇亮相第二届中国国际进口博览会(China International import Expo,简称CIIE或进博会)。</p>
	          <img src="http://p3.pstatp.com/large/pgc-image/185592ace30542cdb451b0e0b8b5d8f6" alt="Kate Somerville凯诗薇亮相进博会高端奢侈品区">
	          <p>最受好莱坞名媛喜爱的护肤品牌 Kate Somerville凯诗薇和LVMH,雅诗兰黛、欧莱雅等大集团一起受邀成为上海进博会奢侈品馆中的一员。</p>
	          <img src="http://p1.pstatp.com/large/pgc-image/63f211702e4742fdb97e97bd24d2a365" alt="Kate Somerville凯诗薇亮相进博会高端奢侈品区">
	          <p>你眼里的Kate Somerville凯诗薇是什么样子的?来到进博会,给你展示一个更直观的凯诗薇品牌。</p>
	          <img src="http://p1.pstatp.com/large/pgc-image/a58215f14ca849e2b6044f823e747970" alt="Kate Somerville凯诗薇亮相进博会高端奢侈品区">
	          <p>进博会进展到第五天,Kate Somerville凯诗薇优秀的产品体验和肤感吸引了很多合作伙伴和消费者,展台一直非常的忙碌。除了进博会本身的流量加持,还要感谢众多粉丝对品牌和产品的喜爱和支持。</p>
	          <p>Kate Somerville凯诗薇进入中国,一直致力于为国内消费者提供专业的护肤品,此次的进博会给予了凯诗薇很好的机会与众多消费者亲密接触,为大家展示一个更立体全面的品牌形象和产品系列。</p>
	          <img src="http://p1.pstatp.com/large/pgc-image/a8b9b842e69b401983a1920efadc0ffc" alt="Kate Somerville凯诗薇亮相进博会高端奢侈品区">
	          <img src="http://p1.pstatp.com/large/pgc-image/1828ae682da24ed182541bcc7d3595cd" alt="Kate Somerville凯诗薇亮相进博会高端奢侈品区">
	          <p>Kate Somerville凯诗薇诞生于好莱坞,创始人Kate Somerville女士曾为好莱坞众多艺人提供肌肤护理解决方案,非常多好莱坞明星例如英国王妃梅根,美国歌后泰勒斯威夫特, 维密超模卡里克劳斯和亚历山大安布罗休等,将Kate Somerville凯诗薇产品作为外出工作的必备之物。同时国内也有非常多顶级博主也是我们品牌的忠实粉丝,例如李佳琪,小蛮蛮小,张大奕等等。
	          “I believe everyone deserves beautiful skin and it’s my promise to get you there.” 创始人的创造品牌和产品的理念,让非常多的消费者拥有了美丽肌肤,从而拥有了自信的人生。</p>
	          <img src="http://p1.pstatp.com/large/pgc-image/710d6ec536f143e49f20f257e4a68256" alt="Kate Somerville凯诗薇亮相进博会高端奢侈品区">
	          <img src="http://p3.pstatp.com/large/pgc-image/01e2778c582040a182d65306ffe06b71" alt="Kate Somerville凯诗薇亮相进博会高端奢侈品区">
	          <img src="http://p3.pstatp.com/large/pgc-image/df199020c1ab46ceb86b14d8822e88c2" alt="Kate Somerville凯诗薇亮相进博会高端奢侈品区">
	          <p>作为品牌最主力的产品注氧精华,它是王妃梅根最喜爱的产品,主要功能是帮助肌肤紧致和提亮,对于熬夜党特别适合。另一款明星单品祛痘水也是李佳琪多次强力推荐的秘密祛痘武器,隔夜消痘,还能预防痘痘复发。
	            山羊奶洁面是美国歌后泰勒斯威夫特在肌肤敏感换季的时候特别喜欢的洁面,因为产品含有的羊奶成分,可以稳定和治愈敏感换季期的肌肤和敏感性肌肤。</p>
	            <img src="http://p1.pstatp.com/large/pgc-image/3237b3525491458a8469f7fa482e485a" alt="Kate Somerville凯诗薇亮相进博会高端奢侈品区">
	            <p>本届进博会将持续到11月10日,期间Kate Somerville凯诗薇展区将全天候开放,让每一位来宾都能亲身体验来自好莱坞的名媛护肤,感谢众多的粉丝的关注和支持。</p>
	            <p>临近双十一,作为全年最大的一场特惠活动,凯诗薇也是提供了众多优惠感谢粉丝的支持和喜爱,优惠活动有:</p>
	            <p>Kate Somerville凯诗薇明星产品注氧精华前2000件买一赠二;</p>
	            <p>更多Kate Somerville凯诗薇的品牌了解,请点击以下链接,了解凯诗薇15周年庆:</p>
	            <p>购买英国王妃挚爱祛痘洁面祛痘精华加赠山羊奶洁面;</p>
	            <p>旗舰店首页有更多超值大额券,更有会员特权,积分当钱花。</p>
	            <p>Kate Somerville超有诚意的第一个双十一,千万别错过哦,详情请前往Kate Somerville天猫旗舰店了解更多哦!</p>
	        `
	}
]
